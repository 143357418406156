@font-face {
  font-family: "jazzy";
  src: url("../font/Let'sJazz-UnicaseFreebie.otf") format("opentype");
}
html {
  height: 100vh;
  width: 100vw;
  scrollbar-width: none;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

* {
  margin: 0;
  padding: 0;
}

body {
  width: 100vw;
  height: 100vh;
}

.banner {
  top: 0;
  position: sticky;
  width: 100vw;
  height: 15vh;
  background-color: #f6f8fa;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner nav {
  display: flex;
  flex-wrap: nowrap;
  width: 60vw;
}
@media screen and (max-width: 600px) {
  .banner nav {
    width: 100vw;
  }
}
.banner nav h1 {
  display: flex;
  flex-wrap: nowrap;
  font-family: "jazzy";
  color: #362626;
}
@media screen and (max-width: 600px) {
  .banner nav h1 {
    font-size: 6vw;
    white-space: nowrap;
  }
}
@media screen and (min-width: 600px) and (max-width: 1080px) {
  .banner nav h1 {
    font-size: 5vw;
    white-space: nowrap;
  }
}
@media screen and (min-width: 1080px) and (max-width: 1460px) {
  .banner nav h1 {
    font-size: 4vw;
  }
}
@media screen and (min-width: 1460px) and (max-width: 1920px) {
  .banner nav h1 {
    font-size: 3vw;
  }
}
@media screen and (min-width: 1920px) {
  .banner nav h1 {
    font-size: 3vw;
  }
}
.banner nav div {
  height: inherit;
  width: 150px;
}
@media screen and (max-width: 600px) {
  .banner nav div {
    width: 80px;
  }
}
.banner nav div a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.banner nav div a img {
  margin-top: 0.5vh;
  display: flex;
  flex-flow: column wrap;
  width: 30%;
  height: 50%;
  -o-object-fit: fill;
     object-fit: fill;
  overflow: hidden;
}

.navbar {
  border: solid 1px #362626;
  position: fixed;
  background-color: rgb(244, 147, 147);
  height: 8vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar button {
  background-color: rgb(244, 147, 147);
  color: #362626;
  font-family: "jazzy";
  font-weight: bold;
  border-style: none;
}
@media screen and (max-width: 1080px) {
  .navbar button {
    margin: 2vw;
    width: 100%;
    height: 8vh;
    font-size: 2vh;
  }
}
@media screen and (min-width: 1080px) and (max-width: 1460px) {
  .navbar button {
    margin-right: 4vw;
    width: 15rem;
    height: 8vh;
    font-size: 3vh;
  }
}
@media screen and (min-width: 1460px) and (max-width: 1920px) {
  .navbar button {
    width: 15vw;
    height: 8vh;
    font-size: 3vh;
  }
}
@media screen and (min-width: 1920px) {
  .navbar button {
    width: 15vw;
    height: 8vh;
    font-size: 3vh;
  }
}
@media screen and (max-width: 600px) {
  .navbar button {
    width: 90%;
    height: 8vh;
    font-size: 1.5vh;
  }
}
.navbar button:hover {
  background-color: #ef6565;
}
.navbar button:disabled {
  background-color: #ed4e4e;
}

main {
  background-color: rgb(227, 252, 255);
}

.progressBar {
  margin-top: 8.1vh;
  position: fixed;
  width: 120vw;
}

/* The progress container (grey background) */
.progress-container {
  margin-left: -15vw;
  width: 120vw;
  height: 1.5vh;
}

/* The progress bar (scroll indicator) */
.progress-bar {
  border: solid 1px #362626;
  height: 1.5vh;
  background: #04AA6D;
  border-radius: 15px 15px;
}

.paragraphe {
  padding-top: 45vh;
  padding-bottom: 20vh;
  -o-object-fit: cover;
     object-fit: cover;
  color: #362626;
  -o-object-position: center;
     object-position: center;
  width: 100%;
  height: 30vh;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-family: "jazzy";
  list-style: none;
}
.paragraphe__topParagraphe {
  width: 50%;
  text-align: center;
  color: rgb(244, 147, 147);
  font-size: 4vh;
}
@media screen and (max-width: 600px) {
  .paragraphe__topParagraphe {
    font-size: 2.5vh;
  }
}
.paragraphe__bottomParagraphe {
  color: rgb(125, 125, 196);
  font-size: 2.5vh;
  text-align: center;
  width: 65%;
}
@media screen and (max-width: 600px) {
  .paragraphe__bottomParagraphe {
    font-size: 1.8vh;
  }
}

.downloadHandler {
  margin-top: 20px;
  height: 64px;
  text-align: center;
}
.downloadHandler__downloadButton {
  font-family: "jazzy";
  border: none;
  font-size: x-large;
  padding: 0 55px;
  margin-top: 5vh;
  background-color: rgb(244, 147, 147);
  color: #f6f8fa;
}
.downloadHandler__downloadButton:active {
  box-shadow: inset -2px -2px 3px rgba(255, 255, 255, 0.6), inset 2px 2px 3px rgba(0, 0, 0, 0.6);
}
.downloadHandler__downloadButton:hover {
  background-color: #ef6565;
}

footer {
  height: 100%;
  background-color: rgb(227, 252, 255);
}
footer hr {
  width: 20%;
  height: 1px;
  margin-left: auto;
  margin-right: auto;
  background-color: #eb3636;
  border: 0 none;
}
@media screen and (max-width: 600px) {
  footer hr {
    width: 45%;
  }
}
footer div {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}
footer div p {
  color: #eb3636;
  font-size: 2vh;
  font-family: "jazzy";
  text-align: center;
}
footer div a {
  width: 50px;
  height: 50px;
  margin-right: 2vw;
  margin-top: 10px;
}
footer div a svg {
  max-height: 75%;
  max-width: 75%;
}
footer div a svg g {
  stroke: #eb3636;
}

.paragrapheAccueil {
  padding-top: 45vh;
  padding-bottom: 20vh;
  -o-object-fit: cover;
     object-fit: cover;
  color: #362626;
  -o-object-position: center;
     object-position: center;
  width: 100%;
  height: 30vh;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  font-family: "jazzy";
  list-style: none;
}
.paragrapheAccueil img {
  width: 21vw;
}
.paragrapheAccueil p {
  font-size: 3vh;
  width: 60%;
  text-align: start;
  margin-left: 4vw;
  color: rgb(244, 147, 147);
}
@media screen and (max-width: 1080px) {
  .paragrapheAccueil {
    flex-flow: column nowrap;
  }
  .paragrapheAccueil img {
    width: 40vw;
    margin-bottom: 4vh;
  }
  .paragrapheAccueil p {
    font-size: 1.5vh;
    text-align: center;
  }
}/*# sourceMappingURL=style.css.map */