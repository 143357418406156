$primary-color : rgb(244, 147, 147);
$secondary-color : #f6f8fa;
$third-color : rgb(227, 252, 255);
$fourth-color: #362626;

$breakpoints: (L: 1920px,
    M: 1460px,
    S: 1080px,
    XLmobile: 600px,
);

@mixin navbarMediaQ {
    @media screen and (max-width: map-get($breakpoints, S)) {
        margin: 2vw;
        width: 100%;
        height: 8vh;
        font-size: 2vh;
    }

    @media screen and (min-width: map-get($breakpoints, S)) and (max-width: map-get($breakpoints, M)) {
        margin-right: 4vw;
        width: 15rem;
        height: 8vh;
        font-size: 3vh;
    }

    @media screen and (min-width: map-get($breakpoints, M)) and (max-width: map-get($breakpoints, L)) {
        width: 15vw;
        height: 8vh;
        font-size: 3vh;
    }

    @media screen and (min-width: map-get($breakpoints, L)) {
        width: 15vw;
        height: 8vh;
        font-size: 3vh;
    }

    @media screen and (max-width: map-get($breakpoints, XLmobile)) {
        width: 90%;
        height: 8vh;
        font-size: 1.5vh;
    }
}

@mixin TittleMediaQ {
    @media screen and (max-width: map-get($breakpoints, XLmobile)) {
        font-size: 6vw;
        white-space: nowrap;
    }

    @media screen and (min-width: map-get($breakpoints, XLmobile)) and (max-width: map-get($breakpoints, S)) {
        font-size: 5vw;
        white-space: nowrap;
    }

    @media screen and (min-width: map-get($breakpoints, S)) and (max-width: map-get($breakpoints, M)) {
        font-size: 4vw;
    }

    @media screen and (min-width: map-get($breakpoints, M)) and (max-width: map-get($breakpoints, L)) {
        font-size: 3vw;
    }

    @media screen and (min-width: map-get($breakpoints, L)) {
        font-size: 3vw;
    }
}

@mixin paragrapheMediaQ {

    @media screen and (max-width: map-get($breakpoints, XLmobile)) {
        font-size: 7vw;
    }

    @media screen and (min-width: map-get($breakpoints, XLmobile)) and (max-width: map-get($breakpoints, S)) {
        font-size: 5vw;
        width: 60vw;
    }

    @media screen and (min-width: map-get($breakpoints, S)) and (max-width: map-get($breakpoints, M)) {
        font-size: 4vw;
    }

    @media screen and (min-width: map-get($breakpoints, M)) and (max-width: map-get($breakpoints, L)) {
        font-size: 3vw;
    }

    @media screen and (min-width: map-get($breakpoints, L)) {
        font-size: 3vw;
    }
}

@font-face {
    font-family: "jazzy";
    src: url('../font/Let\'sJazz-UnicaseFreebie.otf') format("opentype");
}

html {
    height: 100vh;
    width: 100vw;
    scrollbar-width: none;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
}

* {
    margin: 0;
    padding: 0;
}

body {
    width: 100vw;
    height: 100vh;
}

.banner {
    top: 0;
    position: sticky;
    width: 100vw;
    height: 15vh;
    background-color: $secondary-color;
    display: flex;
    align-items: center;
    justify-content: center;

    nav {
        display: flex;
        flex-wrap: nowrap;
        width: 60vw;

        @media screen and (max-width: map-get($breakpoints, XLmobile)) {
            width: 100vw;
        }

        h1 {
            display: flex;
            flex-wrap: nowrap;
            font-family: "jazzy";
            @include TittleMediaQ();
            color: $fourth-color;
        }

        div {
            height: inherit;
            width: 150px;

            @media screen and (max-width: map-get($breakpoints, XLmobile)) {
                width: 80px;
            }


            a {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 5px;



                img {
                    margin-top: 0.5vh;
                    display: flex;
                    flex-flow: column wrap;
                    width: 30%;
                    height: 50%;
                    object-fit: fill;
                    overflow: hidden;
                }
            }
        }
    }
}

.navbar {
    border: solid 1px $fourth-color;
    position: fixed;
    background-color: $primary-color;
    height: 8vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        background-color: $primary-color;
        color: $fourth-color;
        font-family: "jazzy";
        font-weight: bold;
        border-style: none;

        @include navbarMediaQ();

        &:hover {
            background-color: darken($color: $primary-color, $amount: 10%);
        }

        &:disabled {
            background-color: darken($color: $primary-color, $amount: 15%);
        }
    }
}

main {
    background-color: $third-color;
}

.progressBar {
    margin-top: 8.1vh;
    position: fixed;
    width: 120vw;
}

/* The progress container (grey background) */
.progress-container {
    margin-left: -15vw;
    width: 120vw;
    height: 1.5vh;
}

/* The progress bar (scroll indicator) */
.progress-bar {
    border: solid 1px $fourth-color;
    height: 1.5vh;
    background: #04AA6D;
    border-radius: 15px 15px;
}

.paragraphe {
    padding-top: 45vh;
    padding-bottom: 20vh;
    object-fit: cover;
    color: $fourth-color;
    object-position: center;
    width: 100%;
    height: 30vh;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    scroll-behavior: smooth;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    font-family: 'jazzy';
    list-style: none;

    &__topParagraphe {
        width: 50%;
        text-align: center;
        color: rgb(244, 147, 147);
        font-size: 4vh;

        @media screen and (max-width: map-get($breakpoints, XLmobile)) {
            font-size: 2.5vh;
        }

    }

    &__bottomParagraphe {
        color: rgb(125, 125, 196);
        font-size: 2.5vh;
        text-align: center;
        width: 65%;

        @media screen and (max-width: map-get($breakpoints, XLmobile)) {
            font-size: 1.8vh;
        }
    }
}

.downloadHandler {
    margin-top: 20px;
    height: 64px;
    text-align: center;

    &__downloadButton {
        font-family: "jazzy";
        border: none;
        font-size: x-large;
        padding: 0 55px;
        margin-top: 5vh;
        background-color: $primary-color;
        color: $secondary-color;

        &:active {
            box-shadow: inset -2px -2px 3px rgba(255, 255, 255, 0.6), inset 2px 2px 3px rgba(0, 0, 0, 0.6);
        }

        &:hover {
            background-color: darken($color: $primary-color, $amount: 10);
        }
    }
}

footer {
    height: 100%;
    background-color: $third-color;


    hr {
        width: 20%;
        height: 1px;
        margin-left: auto;
        margin-right: auto;
        background-color: darken($color: $primary-color, $amount: 20);
        border: 0 none;

        @media screen and (max-width: map-get($breakpoints, XLmobile)) {
            width: 45%;
        }
    }

    div {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        margin-top: 0;

        p {
            color: darken($color: $primary-color, $amount: 20);
            font-size: 2vh;
            font-family: "jazzy";
            text-align: center;
        }

        a {
            width: 50px;
            height: 50px;
            margin-right: 2vw;
            margin-top: 10px;

            svg {
                max-height: 75%;
                max-width: 75%;

                g {
                    stroke: darken($color: $primary-color, $amount: 20);
                }
            }
        }
    }
}

.paragrapheAccueil {
    padding-top: 45vh;
    padding-bottom: 20vh;
    object-fit: cover;
    color: $fourth-color;
    object-position: center;
    width: 100%;
    height: 30vh;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    font-family: 'jazzy';
    list-style: none;

    img {
        width: 21vw;
    }

    p {
        font-size: 3vh;
        width: 60%;
        text-align: start;
        margin-left: 4vw;
        color: $primary-color;
    }

    @media screen and (max-width: map-get($breakpoints, S)) {
        flex-flow: column nowrap;

        img {
            width: 40vw;
            margin-bottom: 4vh;
        }

        p {
            font-size: 1.5vh;
            text-align: center;
        }
    }
}